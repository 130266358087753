<div class="flex flex-col w-full h-full">
  <section class="justify-center dialog-content gap-y-30" @fadeIn>
    <div class="flex items-center justify-center mx-10">
      <img class="w-auto h-60" src="./assets/images/icons/money-guide-logo-color.svg" alt="MoneyGuide logo">
    </div>
    <span class="m-10 text-lg">
      Welcome to MoneyGuide Elite by Envestnet. Please enter your <strong>Institutional ID</strong> below to continue.
    </span>
    <form [formGroup]="institutionalIdForm" (submit)="onCompleteSubmit()">
      <div class="flex flex-col flex-shrink-0 mx-10 gap-y-5">
        <label for="institutionalId" class="text-sm label text-gray" for="institutionalId">Institutional ID</label>
        <input class="input" type="text" name="institutionalId" formControlName="institutionalId"
          [disabled]="isDisabled || isSubmitting" placeholder="EX: 1234"
          appCyData="moneyGuideInput" />
          @if (institutionalIdCtrl.touched && institutionalIdCtrl.errors) {
            <span class="w-full text-xs text-red"
              >
              @if (institutionalIdCtrl.hasError('required')) {
                <span>This field is required.</span>
              }
            </span>
          }
        </div>
      </form>
      <div class="flex flex-shrink-0 mx-10">
        <span class="">Need help finding your ID? <a class="underline text-blue-dark" href="https://www.figmarketing.com/files/resources/LocateInstitutionId.pdf" target="_blank"
        aria-label="Log into MoneyGuide Elite">Log into MoneyGuide to find out how.</a></span>
      </div>
    </section>
    <footer @fadeIn class="flex items-center justify-center p-20 border-t border-solid border-gray-lighter">
      @if (isSaved) {
        <span  class="w-full mx-10 text xs text-green-dark">You are connected.</span>
      }
      <button mat-button class="m-10 ml-auto button is-primary" type="submit"
        (click)="isSubmitting = true; !institutionalIdForm.invalid ? onCompleteSubmit() : ''"
        [disabled]="isDisabled || isSubmitting || institutionalIdForm.invalid || !institutionalIdCtrl.value"
        appCyData="moneyGuideButton">
        Launch{{isSubmitting ? 'ing' : ''}}
      </button>
    </footer>
  </div>
