import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { PortalComponent } from './portal.component';
import { SharedModule } from '../shared/shared.module';
import { CarriersTrainingComponent } from './components/carriers-training/carriers-training.component';
import { LeaderboardComponent } from './modals/leaderboard/leaderboard.component';
import { RedeemMarketingDollarsComponent } from './modals/redeem-marketing-dollars/redeem-marketing-dollars.component';
import { SubmitPaperApplicationComponent } from './modals/submit-paper-application/submit-paper-application.component';
import { SupportComponent } from './modals/support/support.component';
import { MyCarriersComponent } from './components/carriers-training/components/my-carriers/my-carriers.component';
import { RequirementsComponent } from './components/business/components/requirements/requirements.component';
import { PolicyDetailsComponent } from './components/business/components/policy-details/policy-details.component';
import { PortalRoutingModule } from './portal-routing.module';
import { ResourceDetailsComponent } from './components/explore/components/resource-details/resource-details.component';
import { NextComponent } from './components/explore/templates/next/next.component';
import { EnrollmentFormComponent } from './modals/enrollment-form/enrollment-form.component';
import { RenewalFormComponent } from './modals/renewal-form/renewal-form.component';
import { PurchasePageComponent } from './components/purchase-page/purchase-page.component';
// import { customCurrencyMaskConfig } from '../shared/config/ngx-currency.config';
import { PurchaseModalComponent } from './modals/purchase-modal/purchase-modal.component';
import { ElevateComponent } from './components/explore/templates/elevate/elevate.component';
import { DigitalMarketingAssetsComponent } from './components/explore/templates/digital-marketing-assets/digital-marketing-assets.component';
import { PozWorkflowComponent } from './components/explore/components/enrollment-purchase-workflows/poz-workflow/poz-workflow.component';
import { ForceCrmWorkflowComponent } from './components/explore/components/enrollment-purchase-workflows/force-crm-workflow/force-crm-workflow.component';
import { SurgeWorkflowComponent } from './components/explore/components/enrollment-purchase-workflows/surge-workflow/surge-workflow.component';
import { RetireUpWorkflowComponent } from './components/explore/components/enrollment-purchase-workflows/retire-up-workflow/retire-up-workflow.component';
import { HegnaWorkflowComponent } from './components/explore/components/enrollment-purchase-workflows/hegna-workflow/hegna-workflow.component';
import { DisabilityComponent } from './components/product-rd/components/education/components/disability/disability.component';
import { MedicareComponent } from './components/product-rd/components/education/components/medicare/medicare.component';
import { QuestionnairePopupComponent } from './modals/questionnaire-popup/questionnaire-popup.component';
import { HegnaComponent } from './components/explore/templates/hegna/hegna.component';
import { ElevateWorkflowComponent } from './components/explore/components/enrollment-purchase-workflows/elevate-workflow/elevate-workflow.component';
import { OrderCompleteComponent } from './components/externals/order-complete/order-complete.component';
import { SignatureCompleteComponent } from './components/externals/signature-complete/signature-complete.component';
import { MarketingExpenseHistoryComponent } from './modals/marketing-expense-history/marketing-expense-history/marketing-expense-history.component';
import { CopyApplicationModalComponent } from './modals/copy-application-modal/copy-application-modal.component';
import { StartIllustrationComponent } from './modals/start-illustration/start-illustration/start-illustration.component';
import { BusinessComponent } from './components/business/business.component';
import { PendingPolicyListComponent } from './components/business/components/pending-policy-list/pending-policy-list.component';
import { PolicyListSidebarComponent } from './components/business/components/policy-list-sidebar/policy-list-sidebar.component';
import { InforcePolicyListComponent } from './components/business/components/lifecycle-policy-list/inforce-policy-list.component';
import { ContractingLicensesComponent } from './modals/contracting-licenses/contracting-licenses.component';
import { EAppWorkflowStartApplicationComponent } from './modals/eapp-workflow-start-application/eapp-workflow-start-application.component';
import { InformalPolicyListComponent } from './components/business/components/informal-policy-list/informal-policy-list.component';
import { CaseStatusNotesComponent } from './modals/case-status-notes/case-status-notes.component';
import { LifeInsuranceComponent } from './components/product-rd/components/education/components/life-insurance/life-insurance.component';
import { ApplicationHistoryComponent } from './components/product-rd/components/e-application/application-history/application-history.component';
import { InformalPolicyDetailsComponent } from './components/business/components/informal-policy-details/informal-policy-details.component';
import { FactFinderInstructionsComponent } from './modals/fact-finder-instructions/fact-finder-instructions.component';
import { IndustryEventComponent } from './components/explore/components/industry-event/industry-event.component';
import { CreativeServicesWorkflowComponent } from './components/explore/components/enrollment-purchase-workflows/creative-services-workflow/creative-services-workflow.component';
import { UserLogoUploadComponent } from './modals/user-logo-upload/user-logo-upload.component';
import { InvestmentPolicyListComponent } from './components/business/components/investment-policy-list/investment-policy-list.component';
import { AnnuityRateWatchMygaComponent } from './components/explore/components/annuity-rate-watch-myga/annuity-rate-watch-myga.component';
import { AnnuityRateWatchLifetimeIncomeToolComponent } from './components/explore/components/annuity-rate-watch-lifetime-income-tool/annuity-rate-watch-lifetime-income-tool.component';
import { EApplicationDownloadsComponent } from './modals/e-application-downloads/e-application-downloads.component';
import { MoneyguideSsoModalComponent } from './modals/moneyguide-sso-modal/moneyguide-sso-modal.component';
import { AnnouncementBannerComponent } from './components/announcement-banner/announcement-banner.component';
import { PendingPolicyTableComponent } from './components/business/components/pending-policy-list/components/pending-policy-table/pending-policy-table.component';
import { InforcePolicyTableComponent } from './components/business/components/lifecycle-policy-list/components/inforce-policy-table/inforce-policy-table.component';
import { InformalPolicyTableComponent } from './components/business/components/informal-policy-list/components/informal-policy-table/informal-policy-table.component';
import { InvestmentPolicyTableComponent } from './components/business/components/investment-policy-list/components/investment-policy-table/investment-policy-table.component';
import { ExploreComponent } from './components/explore/explore.component';
import { ExploreListViewComponent } from './components/explore/components/explore-list-view/explore-list-view.component';
import { ExploreGridViewComponent } from './components/explore/components/explore-grid-view/explore-grid-view.component';
import { LottiePlayer } from '@lottiefiles/lottie-player';
import { PolicyDetailsV2Component } from './components/business/components/policy-details-v2/policy-details-v2.component';
import { PolicyRequirementsV2Component } from './components/business/components/policy-requirements-v2/policy-requirements-v2.component';
import { UpdatesHistoryComponent } from './components/business/modals/updates-history/updates-history.component';
import { AgentMessageInputComponent } from './components/business/components/agent-message-input/agent-message-input.component';
import { NewAgentMessageComponent } from './components/business/modals/new-agent-message/new-agent-message.component';
import { PolicyDetailsSummaryComponent } from './components/business/components/policy-details-v2/components/policy-details-summary/policy-details-summary.component';
import { StageHistoryTimelineComponent } from './components/business/components/policy-details-v2/components/stage-history-timeline/stage-history-timeline.component';
import { PolicyRequirementsModalV2Component } from './components/business/modals/policy-requirements-modal-v2/policy-requirements-modal-v2.component';
import { SearchModule } from './features/search/search.module';
import { TermLifeSsoModaltermLifeSsoModalComponent } from './modals/term-life-sso-modalterm-life-sso-modal/term-life-sso-modalterm-life-sso-modal.component';
import { ManageIntegrationsModalComponent } from './modals/manage-integrations-modal/manage-integrations-modal.component';
import { RedtailIntegrationComponent } from './modals/manage-integrations-modal/components/redtail-integration/redtail-integration.component';
import { IbexusMygaPreviewComponent } from './modals/ibexus-myga-preview/ibexus-myga-preview.component';
import { ContractingV2Component } from './components/carriers-training/components/contracting-v2/contracting-v2.component';
import { GetContractedComponent } from './components/carriers-training/components/get-contracted/get-contracted.component';
import { PaperApplicationUploadHistoryComponent } from './modals/paper-application-upload-history/paper-application-upload-history.component';
import { SubmitPaperApplicationV2Component } from './modals/submit-paper-application-v2/submit-paper-application-v2.component';
import { CpcReportingListComponent } from './components/business/components/cpc-reporting-list/cpc-reporting-list.component';
import { CpcPolicyTableComponent } from './components/business/components/cpc-reporting-list/components/cpc-policy-table/cpc-policy-table.component';
import { MainNavV2Component } from './components/main-nav-v2/main-nav-v2.component';
import { InformalPolicyDetailsV2Component } from './components/business/components/informal-policy-details-v2/informal-policy-details-v2.component';
import { InformalPolicySummaryComponent } from './components/business/components/informal-policy-details-v2/components/informal-policy-summary/informal-policy-summary.component';
import { VismePresentationDialogComponent } from './modals/visme-presentation-dialog/visme-presentation-dialog.component';
import { WealthboxIntegrationComponent } from './modals/manage-integrations-modal/components/wealthbox-integration/wealthbox-integration.component';
import { SalesforceIntegrationComponent } from './modals/manage-integrations-modal/components/salesforce-integration/salesforce-integration.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    PortalComponent,
    CarriersTrainingComponent,
    LeaderboardComponent,
    RedeemMarketingDollarsComponent,
    SubmitPaperApplicationComponent,
    SupportComponent,
    MyCarriersComponent,
    RequirementsComponent,
    PolicyDetailsComponent,
    ResourceDetailsComponent,
    NextComponent,
    EnrollmentFormComponent,
    RenewalFormComponent,
    PurchasePageComponent,
    PurchaseModalComponent,
    ElevateComponent,
    DigitalMarketingAssetsComponent,
    PozWorkflowComponent,
    ForceCrmWorkflowComponent,
    SurgeWorkflowComponent,
    RetireUpWorkflowComponent,
    HegnaWorkflowComponent,
    DisabilityComponent,
    MedicareComponent,
    QuestionnairePopupComponent,
    HegnaComponent,
    ElevateWorkflowComponent,
    OrderCompleteComponent,
    SignatureCompleteComponent,
    MarketingExpenseHistoryComponent,
    ApplicationHistoryComponent,
    CopyApplicationModalComponent,
    StartIllustrationComponent,
    BusinessComponent,
    PendingPolicyListComponent,
    PolicyListSidebarComponent,
    InforcePolicyListComponent,
    ContractingLicensesComponent,
    EAppWorkflowStartApplicationComponent,
    InformalPolicyListComponent,
    CaseStatusNotesComponent,
    LifeInsuranceComponent,
    FactFinderInstructionsComponent,
    InformalPolicyDetailsComponent,
    IndustryEventComponent,
    CreativeServicesWorkflowComponent,
    UserLogoUploadComponent,
    InvestmentPolicyListComponent,
    AnnuityRateWatchMygaComponent,
    AnnuityRateWatchLifetimeIncomeToolComponent,
    EApplicationDownloadsComponent,
    MoneyguideSsoModalComponent,
    AnnouncementBannerComponent,
    PendingPolicyTableComponent,
    InforcePolicyTableComponent,
    InformalPolicyTableComponent,
    InvestmentPolicyTableComponent,
    ExploreComponent,
    ExploreListViewComponent,
    ExploreGridViewComponent,
    PolicyDetailsV2Component,
    PolicyRequirementsV2Component,
    UpdatesHistoryComponent,
    AgentMessageInputComponent,
    NewAgentMessageComponent,
    PolicyDetailsSummaryComponent,
    StageHistoryTimelineComponent,
    PolicyRequirementsModalV2Component,
    TermLifeSsoModaltermLifeSsoModalComponent,
    ManageIntegrationsModalComponent,
    RedtailIntegrationComponent,
    IbexusMygaPreviewComponent,
    ContractingV2Component,
    GetContractedComponent,
    PaperApplicationUploadHistoryComponent,
    SubmitPaperApplicationV2Component,
    CpcReportingListComponent,
    CpcPolicyTableComponent,
    MainNavV2Component,
    InformalPolicyDetailsV2Component,
    InformalPolicySummaryComponent,
    VismePresentationDialogComponent,
    WealthboxIntegrationComponent,
    SalesforceIntegrationComponent,
  ],
  imports: [
    SharedModule,
    PortalRoutingModule,
    // NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    SearchModule,
  ],
  exports: [PortalComponent],
  providers: [
  ]
})
export class PortalModule {
  private ref = LottiePlayer; // Get a reference so it doesn't get tree shaken out
}
