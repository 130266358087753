@if (!loading) {
  <section class="relative" [@fadeIn]>
    <span class="absolute top-0 left-0 z-0 w-full bg-bottom bg-no-repeat bg-cover mt-460"
          style="background-image:url(../../../Images/wave-lg.svg)"></span>
    <section class="relative z-10 grid grid-cols-1 mx-auto gap-x-30 gap-y-30 max-w-1280">
      @if (!resourceUrl) {
        <h1 class="text-xl font-bold">Life Insurance</h1>
      }
      <section class="grid grid-cols-1 gap-x-20 gap-y-20 md:grid-cols-4">
        <ul class="grid grid-cols-2 gap-x-20 gap-y-20 sm:col-span-4">
          @for (cat of categories | orderBy: 'Fields.Order'; track cat; let index = $index) {
            <li class="flex">
              <div class="flex flex-grow overflow-hidden card">
                <div class="flex flex-col flex-grow p-10">
                  <h2 class="m-10 font-bold">{{cat.Fields.Name}}</h2>
                  @if (cat.Fields.Name === 'Advanced Market Resources') {
                    <p class="m-10 mt-0 text-sm">{{cat.Fields.Description}}</p>
                    <a mat-button class="flex items-center justify-center m-10 button is-primary"
                      [amplitudeEvent]="'Life Insurance Resources: Visit Planning Center'"
                      target="_blank" href="https://www.pgih02.biz/asolm/amrc/" referrerpolicy="origin">
                      <span>Visit Planning Center</span>
                    </a>
                  }
                  <ul class="flex flex-col">
                    @for (resource of resources | filterBy: ['Fields.CategoryName']: cat.Fields.Name | filterBy: ['Fields.Featured']: true; track resource) {
                      <li
                          class="flex items-center m-10">
                        <i class="mr-10 material-icons mi-18 text-gray-light">{{resource.Fields.MediaIcon}}</i>
                        <a [attr.href]="resource.Fields.ExternalUrl" target="_blank" [amplitudeEvent]="'Life Insurance Resources: ' + resource.Fields.Name"
                          class="text-sm font-semibold underline hover:text-blue-dark">{{resource.Fields.Name}}</a>
                      </li>
                    }
                    <li class="flex m-10">
                      <i class="mr-10 material-icons mi-18 text-gray">more_horiz</i>
                      <a (click)="scrollTo(cat, cat.Id)" (keypress)="scrollTo(cat, cat.Id)" [amplitudeEvent]="'Life Insurance Resources: View More - ' + cat.Fields.Name"
                        class="text-sm font-semibold underline cursor-pointer text-blue-dark hover:text-blue-darker">
                        View more
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="flex flex-row flex-none w-2/5 bg-center bg-no-repeat bg-cover"
                    style="background:url('{{cat.Fields.BgImgUrl}}')">
                  <div class="flex-grow-0 w-48 h-full mr-auto bg-left bg-no-repeat bg-cover"
                      style="background: url('./assets/images/sm-wave-vertical.svg')"></div>
                </div>
              </div>
            </li>
          }
        </ul>
      </section>
      <section class="grid grid-cols-1 gap-x-30 gap-y-30 mt-30">
        <div class="flex items-center">
          <div class="relative flex mr-10 border border-solid rounded sm:flex-grow-0 sm:w-384 border-gray-lighter">
            <input type="search" class="w-full rounded-r-none input is-lg is-inverted" placeholder="Search..."
                  (ngModelChange)="filterBySearchText(resources, searchText)" [(ngModel)]="searchText" />
            <button type="button" class="rounded-l-none icon-button is-primary is-lg">
              <i class="material-icons">search</i>
            </button>
          </div>
          <button type="button" mat-button class="ml-auto button is-primary is-inverted" (click)="toggleAccordion()">
            <span class="flex items-center justify-center">
              <span>{{!allPanelsOpened ? 'Expand' : 'Collapse'}} All</span>
              <i class="ml-5 material-icons mi-18">{{!allPanelsOpened ? 'unfold_more' : 'unfold_less'}}</i>
            </span>
          </button>
        </div>
        <!-- Show list when no search is active -->
        @if (!showFilteredList) {
          <mat-accordion [multi]="true" [@fadeIn]>
            @for (cat of categories | orderBy: 'Fields.Order'; track cat; let index = $index) {
              <mat-expansion-panel class="mb-20 rounded-lg shadow text-gray-dark" hideToggle id="{{cat.Id}}"
                                  [expanded]="cat.Expanded" (opened)="cat.Expanded = true" (closed)="cat.Expanded = false"
                                  #panel="matExpansionPanel">
                <mat-expansion-panel-header class="flex h-64 p-20" matTooltip="Expand/Collapse Item">
                  <h2 class="m-10 text-base font-bold">{{cat.Fields.Name}}</h2>
                  <i class="m-10 ml-auto transition origin-center material-icons text-gray"
                    [ngClass]="{'transform rotate-180': panel.expanded}">expand_more</i>
                </mat-expansion-panel-header>
                <div class="border-t border-solid md:p-10 border-gray-lighter">
                  <!-- Display featured items that do not have sub cat -->
                  <ul class="flex flex-row flex-wrap">
                    @for (resource of getSpecialFeaturedItems(resources) | filterBy: ['Fields.CategoryName']: cat.Fields.Name | filterBy: ['Fields.Featured']: true | orderBy: 'Fields.Name'; track resource) {
                      <li class="flex w-1/2 p-20 md:w-1/4">
                        <a [attr.href]="resource.Fields.ExternalUrl" target="_blank" [amplitudeEvent]="'Life Insurance Resources: ' + resource.Fields.Name"
                          class="flex flex-col items-center w-full p-20 transition duration-150 bg-opacity-25 rounded bg-blue-lighter hover:bg-opacity-50 focus:ring-4 focus:ring-blue-lighter">
                          <span class="flex items-center justify-center mb-10 bg-opacity-25 rounded-full w-36 h-36 bg-blue-lighter">
                            <i class="material-icons mi-18 text-blue-dark">
                              {{resource.Fields.MediaIcon}}
                            </i>
                          </span>
                          <h3 class="text-sm font-semibold text-center">{{resource.Fields.Name}}</h3>
                        </a>
                      </li>
                    }
                  </ul>
                  @for (subcat of subCategories | filterBy: ['Fields.CategoryName']: cat.Fields.Name | orderBy: 'Fields.Order'; track subcat) {
                    <div
                        class="flex flex-col p-10">
                      @if (subcat.Id === 'recXIz126feASSgFG') {<!--Impairment Fact-Finders-->                      
                        <div class="flex flex-row items-center">
                          <h3 class="m-10 text-sm uppercase text-gray">{{subcat.Fields.Name}}</h3>
                        </div>
                        <ul class="flex flex-row flex-wrap">
                          @for (resource of resources | filterBy: ['Fields.SubCategoryName']: subcat.Fields.Name | orderBy: 'Fields.Name'; track resource) {
                            <li
                                class="flex items-center w-1/2 p-10 md:w-1/4">
                              <i class="mr-10 text-gray-light material-icons mi-18">{{resource.Fields.MediaIcon}}</i>
                              <span class="flex flex-col underline">
                                @if (resource.Fields.DownloadUrl) {
                                  <a class="text-sm font-semibold transition-colors hover:text-blue-dark focus:text-blue-dark"
                                    [amplitudeEvent]="'Life Insurance Resources: ' + resource.Fields.Name"
                                    [attr.href]="resource.Fields.DownloadUrl + resource.Fields.Name + '.pdf'" target="_blank">
                                    {{resource.Fields.Name}}
                                  </a>
                                }
                                <a class="text-sm font-semibold transition-colors text-gray hover:text-blue-dark focus:text-blue-dark"
                                  [amplitudeEvent]="'Life Insurance Resources: ' + resource.Fields.Name"
                                  [attr.href]="resource.Fields.ExternalUrl" target="_blank">
                                  Go to DocuSign
                                </a>
                              </span>
                            </li>
                          }
                        </ul>
                      }
                      @if (subcat.Id !== 'recXIz126feASSgFG') {
                        <h3 class="m-10 text-sm uppercase text-gray whitespace-nowrap">
                          {{subcat.Fields.Name}}
                        </h3>
                        <ul class="flex flex-row flex-wrap">
                          @for (resource of resources | filterBy: ['Fields.SubCategoryName']: subcat.Fields.Name | orderBy: 'Fields.Name'; track resource) {
                            <li
                                class="flex items-center w-1/2 p-10 md:w-1/4">
                              <i class="mr-10 material-icons mi-18 text-gray-light">{{resource.Fields.MediaIcon}}</i>
                              <a [attr.href]="resource.Fields.ExternalUrl" target="_blank" [amplitudeEvent]="'Life Insurance Resources: ' + resource.Fields.Name"
                                class="text-sm font-semibold underline hover:text-blue-dark focus:text-blue-dark">{{resource.Fields.Name}}</a>
                            </li>
                          }
                        </ul>
                      }
                    </div>
                  }
                </div>
              </mat-expansion-panel>
            }
          </mat-accordion>
        }@else {<!-- Show list when search is active -->
          <section class='flex flex-col card md:p-10' [@fadeIn]>
            <h3 class="m-10 text-sm uppercase text-gray">Search Results</h3>
            <ul class="flex flex-row flex-wrap">
              @for (resource of filterBySearchText(resources, searchText) | orderBy: 'Fields.Name'; track resource) {
                <li
                    class="flex items-center w-1/2 p-10 md:w-1/4">
                  <i class="mr-10 material-icons mi-18 text-gray">{{resource.Fields.MediaIcon}}</i>
                  <a [attr.href]="resource.Fields.ExternalUrl" target="_blank" [amplitudeEvent]="'Life Insurance Resources: Search Result - ' + resource.Fields.Name"
                    class="text-sm font-semibold underline hover:text-blue-dark focus:text-blue-dark">{{resource.Fields.Name}}</a>
                </li>
              }
            </ul>
          </section>
        }
      </section>
    </section>
  </section>
}