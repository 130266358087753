<section class="relative flex flex-col flex-grow w-full h-full p-20 overflow-hidden card md:p-30" @fadeIn>
  <a class="absolute flex items-center mb-20 mr-auto text-sm font-medium cursor-pointer text-blue-dark hover:text-blue-darker"
    [routerLink]="'/Portal/Explore'" [fragment]="categoryId" target="_self" aria-label="Back">
    <i class="mr-5 no-underline material-icons mi-18">west</i>
    <span>Back</span>
  </a>
  @if (industryEvent) {
    <div class="flex flex-col items-center justify-center w-full">
      @if (industryEvent.Image || industryEvent.WistiaId) {
        <div class="flex flex-col items-center justify-center w-full pb-20 md:w-2/5 md:h-480">
          @if (industryEvent.Image) {
            <div class="relative block w-full max-w-full bg-center bg-no-repeat"
              [class]="industryEvent.BackgroundImageUrl ? 'card' : ''" @fadeIn
              style="background-image: url('{{industryEvent.BackgroundImageUrl}}')">
              <img class="w-auto max-w-full mx-auto mb-20 overflow-hidden rounded h-192"
                src="./assets/images/icons/{{industryEvent.Image}}"
                [attr.aria-label]="industryEvent.Title + ' Image'" alt="{{industryEvent.Title}} Image" @fadeIn>
                @if (industryEvent.ImageSubtitle) {
                  <div class="text-center" [innerHtml]="industryEvent.ImageSubtitle"></div>
                }
              </div>
            }
            @if (industryEvent.WistiaId) {
              <div class="relative w-full mx-auto overflow-hidden rounded shadow wistia_responsive_padding h-320 min-w-128">
                <div class="wistia_responsive_wrapper"
                  style="height: 100%; left: 0; position: absolute; top: 0; width: 100%;">
                  <span id="resourceVideo{{industryEvent.WistiaId}}"
                    class="wistia_embed wistia_async_{{industryEvent.WistiaId}} popover=true popoverAnimateThumbnail=false videoFoam=false videoQuality=hd-only"
                  style="display: inline-block; height: 100%; width: 100%;" @fadeIn></span>
                </div>
              </div>
            }
          </div>
        }
        <h2 class="mb-10 text-2xl font-bold leading-tight capitalize lg:text-3xl" [innerHtml]="industryEvent.Title">
        </h2>
        <p class="mb-10 text-lg text-bold text-gray md:mb-20">{{industryEvent.Location + ' - ' +
          (industryEvent.DateStart
        | date: 'shortDate' : 'UTC')}}</p>
        @if (industryEvent.Description) {
          <p class="mb-20 text-lg w-400 md:mb-40" [innerHtml]="industryEvent.Description"></p>
        }
        @for (group of industryEvent.IndustryEventGroups; track group; let index = $index) {
          <div class="flex flex-col w-full">
            @if (industryEvent.IndustryEventGroups.length > 1) {
              <h3 class="mb-5 text-xl font-bold text-center" [innerHtml]="group.Name"></h3>
            }
            @if (industryEvent.IndustryEventGroups.length > 1) {
              <p class="mb-20 text-lg text-center" [innerHtml]="group.Date | date: 'M/dd/YYYY' : 'UTC'"></p>
            }
            <div class="flex flex-wrap justify-center w-full gap-y-20 md:gap-y-30">
              @for (event of group.Events; track event; let index = $index) {
                <div class="flex flex-col min-w-200 md:w-1/3 p-15">
                  @if (event.WistiaId) {
                    <div class="relative w-full mx-auto mb-10 overflow-hidden rounded shadow wistia_responsive_padding h-220 md:h-300 min-w-128 md:mb-20"
                      >
                      <div class="wistia_responsive_wrapper"
                        style="height: 100%; left: 0; position: absolute; top: 0; width: 100%;">
                        <span id="resourceVideo{{event.WistiaId}}"
                          class="wistia_embed wistia_async_{{event.WistiaId}} popover=true popoverAnimateThumbnail=false videoFoam=false videoQuality=hd-only"
                        style="display: inline-block; height: 100%; width: 100%;" @fadeIn></span>
                      </div>
                    </div>
                  }
                  <h3 class="text-base font-semibold text-center" [innerHtml]="event.Name"></h3>
                </div>
              }
            </div>
          </div>
        }
      </div>
    }
  </section>