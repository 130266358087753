@if (resource) {
  <section class="flex flex-col items-start justify-start w-full h-full mx-auto md:max-w-1280 p-30" @fadeIn>
    <!--*ngIf="style === 1"-->
    <div id="platformDetailsHeader" class="flex flex-col items-start justify-start"
        [class]="resourceLower !== 'annuityresources' &&
          resourceLower !== 'careplanning' &&
          resourceLower !== 'disabilityresources' &&
          resourceLower !== 'lifeinsurance' &&
          resourceLower !== 'medicareplanning' ? 'mb-20' : 'mb-10'">
      <a class="flex items-center mb-20 text-sm font-medium cursor-pointer text-blue-dark hover:text-blue-darker"
        routerLink="/Portal/Explore" [fragment]="categoryId" target="_self" aria-label="Back">
        <i class="mr-5 no-underline material-icons mi-18">west</i>
        <span>Back</span>
      </a>
      <div class="flex flex-row items-center justify-start">
        <h2 class="mr-20 text-xl font-bold heading" [innerHtml]="resource?.Title"></h2>
        @if (resource.AccessPermissions && hideEnrollment) {
          <span class="px-10 py-5 text-sm rounded-full bg-green-lightest text-green-dark"
                [class]="resource.AdditionalResourceGroups && resource.AdditionalResourceGroups.length && showAdditional ? 'cursor-pointer' : ''"
                (click)="resource.AdditionalResourceGroups && resource.AdditionalResourceGroups.length && showAdditional ? scrollTo('additionalResourceSection') : ''"
                (keypress)="resource.AdditionalResourceGroups && resource.AdditionalResourceGroups.length && showAdditional ? scrollTo('additionalResourceSection') : ''"
                target="0">
            You have access
          </span>
        }
      </div>
    </div>
    @if (resourceLower !== 'annuityresources' &&
    resourceLower !== 'careplanning' &&
    resourceLower !== 'disabilityresources' &&
    resourceLower !== 'medicareplanning' &&
    resourceLower !== 'lifeinsurance') {
      <div class="flex flex-col w-full p-20 card md:p-30">
        <div class="flex flex-col flex-wrap md:flex-row">
          <div class="flex flex-col order-2 w-full pb-20 md:order-1 md:pb-0 md:pr-40"
              [class]="resource?.Img || resource?.WistiaId ? 'md:w-3/5' : ''">
            <div class="text-base leading-loose" [innerHtml]="resource?.Template"></div>
            <div class="flex flex-row flex-wrap items-start">
              @if (resource.LearnMoreUrl) {
                <button type="button" class="mt-20 mr-20 border button is-primary is-inverted border-blue-dark md:mt-30"
                        (click)="openLearnMore(resource.LearnMoreUrl)">
                  Learn More
                </button>
              }
              @for (cta of resource.CallsToAction; track cta) {
                <button type="button"
                        class="mt-20 mr-20 border button is-primary {{!cta.Label.toLowerCase().includes('enroll') ? 'is-inverted border-blue-dark' : ''}} md:mt-30"
                        (click)="openLearnMore(cta.Url)" [attr.aria.label]="cta.Label + ' Link'">
                  {{cta.Label}}
                </button>
              }
              @if (!!resource.EnrollmentSurveyData) {
                <div>
                  <div>
                    @switch (resourceLower) {
                      @case ('creativeservices') {
                        <app-creative-services-workflow [resource]="resource"
                                                        [submitted]="submitted" (clickEvent)="redirectToMarketplaceOne()"></app-creative-services-workflow>
                      }
                      @case ('forcecrm') {
                        <app-force-crm-workflow [resource]="resource"
                                                [submitted]="submitted"></app-force-crm-workflow>
                      }
                      @case ('hegna') {
                        <app-hegna-workflow [resource]="resource" [submitted]="submitted">
                        </app-hegna-workflow>
                      }
                      @case ('retireup') {
                        <app-retire-up-workflow [resource]="resource"
                                                [submitted]="submitted"></app-retire-up-workflow>
                      }
                      @case ('surge') {
                        <app-surge-workflow [resource]="resource" [submitted]="submitted">
                        </app-surge-workflow>
                      }
                      @case ('elevate') {
                        <app-elevate-workflow [resource]="resource"
                                              [submitted]="submitted"></app-elevate-workflow>
                      }
                      @default {
                        <div>
                          @if (!hideEnrollment) {
                            @for (survey of submitted; track survey; let index = $index) {
                              <button type="button" class="mt-20 mr-20 button is-primary md:mt-30"
                                      (click)="!!survey && !survey.isEnrolled ? openEnrollmentModal(survey.surveyId) : ''"
                                      [disabled]="survey.isEnrolled">
                                @if (!!survey && !survey.isEnrolled) {
                                  <span>{{
                                    !!resource.EnrollmentSurveyData.Labels
                                    &&
                                    resource.EnrollmentSurveyData.Labels[index] ?
                                    resource.EnrollmentSurveyData.Labels[index] : 'Enroll Today'}}</span>
                                }
                                @if (!!survey && survey.isEnrolled) {
                                  <span [matTooltip]="!!resource.EnrollmentSurveyData.Labels && resource.EnrollmentSurveyData.Labels[index] ? resource.EnrollmentSurveyData.Labels[index] + ' form has already been submitted' : 'Enrollment form already submitted'" matTooltipPosition="above">Submitted</span>
                                }
                              </button>
                            }
                          }
                        </div>
                      }
                    }
                  </div>
                </div>
              }
              @if (resourceLower === 'powerofzero') {
                <app-poz-workflow [resource]="resource"
                                  [submitted]="submitted">
                </app-poz-workflow>
              }
              @if (resourceLower === 'icomply') {
                <button type="button" class="mt-20 mr-20 button is-primary md:mt-30" (click)="redirectToIcomply()"
                        *showIfOne="'IComply:Access'">
                  GO TO ICOMPLY
                </button>
              }
              @if (resourceLower === 'cannex') {
                <button type="button" class="mt-20 mr-20 button is-primary md:mt-30" (click)="redirectToCannex()">
                  GO TO Cannex
                </button>
              }
              @if (resourceLower === 'annuityratewatch') {
                <button type="button" class="mt-20 mr-20 button is-primary md:mt-30" (click)="redirectToAnnuityRateWatch()">
                  GO TO Annuity Rate Watch
                </button>
              }
              @if (resourceLower === 'truelytics') {
                <a *showIfOne="'Truelytics:Access'" href="/api/Truelytics/Sso"
                  class="pt-10 mt-20 mr-20 button is-primary md:mt-30" target="_blank" rel="noopener">Login to
                  Truelytics</a>
              }
            </div>
            @if (resource.Disclaimer) {
              <div class="mx-20 mt-20 italic text-justify md:mt-30" [innerHtml]="resource.Disclaimer"></div>
            }
          </div>
          @if (resource.Img || resource.WistiaId || resource.AdditionalImages?.length) {
            <div class="flex flex-col items-center order-1 w-full pb-20 md:w-2/5 md:h-480 md:order-2 md:pb-0">
              @if (resource.Img) {
                <div class="relative block w-4/5 max-w-full m-20" [@fadeIn]>
                  <img class="w-auto max-w-full mx-auto mb-20 overflow-hidden rounded"
                      [class]="resource.ImgSize ? resource.ImgSize : 'h-192'"
                      src="./assets/images/icons/{{resource.Img}}" @fadeIn [alt]="resource.ImgSubtitle">
                  @if (!!resource.ImgSubtitle) {
                    <div class="text-center" [innerHtml]="resource.ImgSubtitle"></div>
                  }
                </div>
              }
              @if (resource.WistiaId) {
                <div class="relative w-full mx-auto overflow-hidden rounded shadow wistia_responsive_padding h-320 min-w-128">
                  <div class="wistia_responsive_wrapper"
                      style="height: 100%; left: 0; position: absolute; top: 0; width: 100%;">
                    <span id="resourceVideo{{resource.WistiaId}}"
                          class="wistia_embed wistia_async_{{resource.WistiaId}} popover=true popoverAnimateThumbnail=false videoFoam=false videoQuality=hd-only"
                          style="display: inline-block; height: 100%; width: 100%;" @fadeIn></span>
                  </div>
                </div>
              }
              @if (resourceLower === 'theamericancollege') {
                <div class="relative block w-full max-w-full p-20 text-center" [@fadeIn]>
                  <h4 class="mb-10 text-base"><strong>Why Designations Are Important</strong></h4>
                  <iframe class="mx-auto w-9/10 h-256"
                          src="https://www.youtube.com/embed/eXpuAoR1WtU?rel=0&amp;showinfo=0" frameborder="0"
                          allow="autoplay; encrypted-media" allowfullscreen @fadeIn></iframe>
                </div>
              }
              @for (img of resource.AdditionalImages; track img) {
                <div class="relative block w-5/6 max-w-full m-20" [@fadeIn]>
                  <a [href]="img.Url" target="_blank" aria-label="Click to see full version">
                    <img class="w-auto max-w-full mx-auto mb-20 overflow-hidden rounded shadow-md" [attr.aria-label]="img.Label"
                        [class]="img.ImageSize ? img.ImageSize : 'h-220'"
                        [src]="img.Url" @fadeIn [alt]="img.Subtitle + ' image'"></a>
                  @if (!!img.Subtitle) {
                    <div class="text-center" [innerHtml]="img.Subtitle"></div>
                  }
                </div>
              }
            </div>
          }
        </div>
      </div>
    }
    @if (resourceLower === 'careplanning') {
      <div class="">
        <app-care-planning class="p-0"></app-care-planning>
      </div>
    }
    @if (resourceLower === 'disabilityresources') {
      <div class="">
        <app-disability class="p-0"></app-disability>
      </div>
    }
    @if (resourceLower === 'lifeinsurance') {
      <div class="">
        <app-life-insurance class="p-0"></app-life-insurance>
      </div>
    }
    @if (resourceLower === 'medicareplanning') {
      <div class="">
        <app-medicare class="p-0"></app-medicare>
      </div>
    }
    @if (resource.AdditionalTemplate) {
      <div class="flex flex-col w-full p-20 mt-20 card md:p-30 md:mt-30">
        <div class="text-base leading-loose" [innerHtml]="resource.AdditionalTemplate"></div>
      </div>
    }
    @if (resourceLower === 'covid19') {
      <div class="flex flex-col w-full p-20 mt-20 card md:p-30 md:mt-30">
        <app-digital-marketing-assets></app-digital-marketing-assets>
      </div>
    }
    @if (resourceLower === 'elevate') {
      <div class="flex flex-col w-full p-20 mt-20 card md:p-30 md:mt-30"
          style="background:url('./assets/images/elevate-bg.png') 50% 75% no-repeat">
        <app-elevate></app-elevate>
      </div>
    }
    @if (resourceLower === 'next' && showAdditionalResources) {
      <div class="flex flex-col w-full p-20 mt-20 card md:p-30 md:mt-30">
        <app-next></app-next>
      </div>
    }
    @if (resourceLower === 'hegna' && showAdditional) {
      <div class="mt-20 md:mt-30">
        <app-hegna></app-hegna>
      </div>
    }
    @if (resource.AdditionalResourceGroups && resource.AdditionalResourceGroups.length && showAdditional) {
      <div class="mt-20 md:mt-30" #additionalResourceSection id="additionalResourceSection">
        <h2 class="mb-20 text-xl font-bold text-gray-dark">Resources</h2>
        <!-- <div class="flex items-center justify-start flex-layout mb-30">
                <div class="relative flex flex-row items-center justify-start mr-10 sm:flex-grow-0 sm:w-384">
                  <i class="absolute transform -translate-y-1/2 fig-24px fig-search top-1/2 left-5 text-gray"></i>
                  <input type="search" class="w-full input is-lg pl-36" placeholder="Search..." (keyup)="applyFilters()"
                    [(ngModel)]="searchText" />
                  </div>
                  <button class="mr-auto icon-button is-primary is-inverted" aria-label="Clear search box" *ngIf="!!searchText"
                    (click)="clearSearch()"><i class="material-icons">close</i></button>
                  </div> -->
        @for (resourceGroup of resource.AdditionalResourceGroups; track resourceGroup) {
          <div class="mb-20 md:mb-30">
            <h2 class="mb-10 text-lg font-semibold text-gray-dark" [innerHtml]="resourceGroup.GroupName"></h2>
            <div class="grid flex-grow-0 grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-x-20 gap-y-20">
              @for (resource of resourceGroup.AdditionalResources | orderBy: 'Name'; track resource) {
                <div class="flex flex-col p-20 overflow-hidden transition duration-150 bg-center bg-no-repeat bg-cover cursor-pointer card hover:shadow-lg md:p-30">
                  @if (resource.Url) {
                    <div class="flex flex-col items-center w-full h-full"
                        [attr.aria-label]="'Open ' + resource.Name"
                        (click)="openLearnMore('https://www.figmarketing.com/files/resources/' + resource.Url)"
                        (keypress)="openLearnMore('https://www.figmarketing.com/files/resources/' + resource.Url)">
                      @if (resource.FigIcon) {
                        <i class="mb-20 fig-48px text-gray-light" [class]="resource.FigIcon"></i>
                      }
                      @if (resource.Icon) {
                        <span class="mb-20 mi-48 text-gray-light material-icons">{{resource.Icon}}</span>
                      }
                      <p class="text-center text-gray-dark" [innerHtml]="resource.Name"></p>
                    </div>
                  }
                  @if (resource.ExternalUrl) {
                    <div class="flex flex-col items-center w-full h-full"
                        [attr.aria-label]="'Open ' + resource.Name" (click)="openLearnMore(resource.ExternalUrl)" (keypress)="openLearnMore(resource.ExternalUrl)">
                      @if (resource.FigIcon) {
                        <i class="mb-20 fig-36px text-gray-light" [class]="resource.FigIcon"></i>
                      }
                      @if (resource.Icon) {
                        <span class="mb-20 mi-36 text-gray-light material-icons">{{resource.Icon}}</span>
                      }
                      <p class="text-center text-gray-dark" [innerHtml]="resource.Name"></p>
                    </div>
                  }
                  @if (resource.WistiaId) {
                    <div class="flex flex-col items-center w-full h-full">
                      <div
                          class="relative w-full mx-auto mb-20 overflow-hidden rounded shadow wistia_responsive_padding h-100 min-w-128">
                        <div class="wistia_responsive_wrapper"
                            style="height: 100%; left: 0; position: absolute; top: 0; width: 100%;">
                          <span id="resourceVideo{{resource.WistiaId}}"
                                class="wistia_embed wistia_async_{{resource.WistiaId}} popover=true popoverAnimateThumbnail=false videoFoam=false videoQuality=hd-only"
                                style="display: inline-block; height: 100%; width: 100%;"> </span>
                        </div>
                      </div>
                      <p class="text-center text-gray-dark" [innerHtml]="resource.Name"></p>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        }
        <p class="mt-20 text-sm italic disclaimer text-gray-dark md:mt-30"
          [innerHtml]="resource.AdditionalResourceDisclaimer"></p>
      </div>
    }
  </section>
}