<div class="flex flex-col w-full p-20 bg-white gap-y-20">
  @if (reportType === 'Fixed') {
    <div class="flex flex-col">
      <label [for]="allLobsSelected" class="mb-10 text-sm font-semibold text-gray">Lines of Business:</label>
      <ul class="flex flex-col">
        <li>
          <mat-checkbox class="text-sm checkbox" [(ngModel)]="allLobsSelected"
                        (ngModelChange)="toggleAllCheckboxes(lobs, selectedLobIds, allLobsSelected, 'LinesOfBusiness')">Select All
          </mat-checkbox>
        </li>
        @for (line of lobs; track line) {
          <li>
            <mat-checkbox class="text-sm checkbox" [(ngModel)]="line.Selected"
                          (ngModelChange)="toggleCheckbox(lobs, selectedLobIds, line)">{{line.Name}}</mat-checkbox>
          </li>
        }
      </ul>
    </div>
  }
  @if (showDateFilter) {
    <div class="flex flex-col">
      <span class="mb-10 text-sm font-semibold text-gray">Select Inforce Date Range:</span>
      <div class="grid grid-cols-2 gap-x-10">
        <div class="flex flex-col">
          <label class="mb-10 text-xs font-semibold text-gray" [for]="selectedStartDate">Start Date (mm/dd/yyyy)</label>
          <div class="flex items-center border rounded border-gray-light">
            <input matInput [matDatepicker]="startDate" class="input is-inverted"
                  [(ngModel)]="selectedStartDate" (ngModelChange)="onDateRangeChange()"
                  [matDatepickerFilter]="validDate">
            <mat-datepicker-toggle matSuffix [for]="startDate" class="text-blue-dark"></mat-datepicker-toggle>
          </div>
          <mat-datepicker #startDate disabled="false"></mat-datepicker>
        </div>
        <div class="flex flex-col">
          <label class="mb-10 text-xs font-semibold text-gray" [for]="selectedEndDate">End Date (mm/dd/yyyy)</label>
          <div class="flex items-center border rounded border-gray-light">
            <input matInput [matDatepicker]="endDate" class="input is-inverted"
                  [(ngModel)]="selectedEndDate" (ngModelChange)="onDateRangeChange()"
                  [matDatepickerFilter]="validDate">
            <mat-datepicker-toggle matSuffix [for]="endDate" class="text-blue-dark"></mat-datepicker-toggle>
          </div>
          <mat-datepicker #endDate disabled="false"></mat-datepicker>
        </div>
      </div>
    </div>
  }
  @if (subEntities && subEntities.length > 0 && reportType !== 'Disability') {
    <div class="flex flex-col">
      <label class="mb-10 text-sm font-semibold text-gray" [for]="subEntities">Commission Groups:</label>
      <ul class="flex flex-col">
        @for (subEntity of subEntities; track subEntity) {
          <li>
            <mat-checkbox class="text-sm checkbox" [(ngModel)]="subEntity.Selected"
                          (ngModelChange)="toggleCheckbox(subEntities, selectedSubEntities, subEntity)">{{subEntity.CommissionGroup}}</mat-checkbox>
          </li>
        }
      </ul>
    </div>
  }
  @if (agents && agents.length > 0) {
    <div class="flex flex-col">
      <label class="mb-10 text-sm font-semibold text-gray" [for]="allAgentsSelected">Sub-agents:</label>
      <ul class="flex flex-col">
        <li>
          <mat-checkbox class="text-sm checkbox" [(ngModel)]="allAgentsSelected"
                        (ngModelChange)="toggleAllCheckboxes(agents, selectedAgentIds, allAgentsSelected, 'Agents')">Select All
          </mat-checkbox>
        </li>
        @for (agent of agents; track agent) {
          <li>
            <mat-checkbox class="text-sm checkbox" [(ngModel)]="agent.Selected"
                          (ngModelChange)="toggleCheckbox(agents, selectedAgentIds, agent)">{{agent.FirstName}}
              {{agent.LastName}} {{agent.CurrentUserTag}}</mat-checkbox>
          </li>
        }
      </ul>
    </div>
  }
</div>